import $ from 'jquery'

$(document).on('turbolinks:load', function () {
  handleFilterDateRaditoChangeState();

  var choice_date_option = $('input[type="radio"][name="user[radio_options]"]:checked');
  let parent_choice = $(choice_date_option).parents('.js-filter-range-time');
  let choice_value = $(choice_date_option).val();

  choiceOptionDateState(parent_choice, choice_value);

  $('#user_products')
    .on('cocoon:after-insert', function (e, added_item) {
      $('#js-link-to-add-item').prev().after(added_item);

      select2ProductAjax();
    }).on('cocoon:before-remove', function (event) {
    var confirmation = confirm('本当に削除しますか?');
    if (confirmation === false) {
      event.preventDefault();
    }
  });
});

function handleFilterDateRaditoChangeState() {
  let item_dom = 'input[type="radio"][name="user[radio_options]"]';

  $(item_dom).change(function () {
    let parent = $(this).parents('.js-filter-range-time');
    var choice_value = this.value;

    choiceOptionDateState(parent, choice_value);
  });
}

function choiceOptionDateState(parent, choice_value) {
  let input_dom1 = 'input[name="order[start_time]"';
  let input_dom2 = 'input[name="order[end_time]"';
  let input_date1 = $(parent).find(input_dom1);
  let input_date2 = $(parent).find(input_dom2);

  if (choice_value == 'on_month') {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    $(input_date1).val(firstDay.getFullYear() + '/' + String(firstDay.getMonth() + 1).padStart(2, '0') + '/' + String(firstDay.getDate()).padStart(2, '0'));
    $(input_date2).val(lastDay.getFullYear() + '/' + String(lastDay.getMonth() + 1).padStart(2, '0') + '/' + String(lastDay.getDate()).padStart(2, '0'));

    $(input_date1).addClass('pointer-event-none');
    $(input_date2).addClass('pointer-event-none');
  } else if (choice_value == 'range_time') {
    $(input_date1).prop('disabled', false);
    $(input_date2).prop('disabled', false);
    $(input_date1).removeClass('pointer-event-none');
    $(input_date2).removeClass('pointer-event-none');
  } else if (choice_value == 'all_time') {
    $(input_date1).val('');
    $(input_date2).val('');
    $(input_date1).addClass('pointer-event-none');
    $(input_date2).addClass('pointer-event-none');
  }
}

function select2ProductAjax() {
  $('.js-source-products-ajax').select2({
    ajax: {
      url: '/products.json',
      dataType: 'json',
      data: function (params) {
        return {
          q: {name_or_code_cont: params.term},
          page: params.page || 1
        }
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: $.map(data, function (item) {
            let item_name = item.name;
            let item_price = item.price;
            let item_unit_type = item.unit_type_localization;

            let item_text_display = item_name + '、' + item.code;

            return {text: item_text_display, id: item.id, price: item_price, unit_type: item_unit_type}
          })
        };
      },
      cache: true,
      minimumInputLength: 1
    },
    placeholder: "商品名",
    language: {
      noResults: function () {
        return "見つかりません";
      }
    }
  });
}
